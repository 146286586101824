import React, {useEffect, useState} from "react";
import {Platform} from "../layouts/Platform";
import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Icon,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Modal,
    Stack,
    Typography,
    useTheme
} from "@mui/material";
import {Apple, CalendarToday, Close, Google} from "@mui/icons-material";
import {useLocalStorage} from "../functions/customHooks/useLocalStorage";
import {useUserStore} from "../store/userStore";
import {useTestStore} from "../store/testStore";
import {useDocument} from "react-firebase-hooks/firestore";
import {firestore} from "../firebase/clientApp";
import {CURRENT_SESSION_ID} from "../config";
import {Link} from "react-router-dom";

export const webinarMap = {
    "13lun1012": {
        "label": "Lunedì 13 Maggio (ore 10:00 - 12:00)",
        "position": 0,
        "calndrId": "A8j8ix8GDm"
    },
    "13lun1416": {
        "label": "Lunedì 13 Maggio (ore 14:30 - 16:30)",
        "position": 1,
        "calndrId": "ToSf9WPtBd"
    },
    "14mar1012": {
        "label": "Martedì 14 Maggio (ore 10:00 - 12:00)",
        "position": 2,
        "calndrId": "L0tHQPCffn"
    },
    "14mar1416": {
        "label": "Martedì 14 Maggio (ore 14:30 - 16:30)",
        "position": 3,
        "calndrId": "rnxxza74MF"
    },
    "23gio1012": {
        "label": "Giovedì 23 Maggio (ore 10:00 - 12:00)",
        "position": 4,
        "calndrId": "kNYAHnDOm2"
    },
    "23gio1416": {
        "label": "Giovedì 23 Maggio (ore 14:30 - 16:30)",
        "position": 5,
        "calndrId": "RwQtu1wSkB"
    },
    "24ven1012": {
        "label": "Venerdì 24 Maggio (ore 10:00 - 12:00)",
        "position": 6,
        "calndrId": "vccw8t8rw6"
    },
    "24ven1416": {
        "label": "Venerdì 24 Maggio (ore 14:30 - 16:30)",
        "position": 7,
        "calndrId": "6kxNKW258a"
    },

    "10mar1012": {
        "label": "Martedì 10 Ottobre (ore 10:00 - 12:00)",
        "position": 0,
        "calndrId": "lQXhBHjjzK"
    },
    "10mar1416": {
        "label": "Martedì 10 Ottobre (ore 14:30 - 16:30)",
        "position": 1,
        "calndrId": "pgvphiRRVm"
    },
    "11mer1012": {
        "label": "Mercoledì 11 Ottobre (ore 10:00 - 12:00)",
        "position": 2,
        "calndrId": "HOe5bx0HfE"
    },
    "11mer1416": {
        "label": "Mercoledì 11 Ottobre (ore 14:30 - 16:30)",
        "position": 3,
        "calndrId": "phhDQC6v8V"
    },
    "17mar1012": {
        "label": "Martedì 17 Ottobre (ore 10:00 - 12:00)",
        "position": 4,
        "calndrId": "3LPJ2He8sV"
    },
    "17mar1416": {
        "label": "Martedì 17 Ottobre (ore 14:30 - 16:30)",
        "position": 5,
        "calndrId": "yIsYiLh9Up"
    },
    "18mer1012": {
        "label": "Mercoledì 18 Ottobre (ore 10:00 - 12:00)",
        "position": 6,
        "calndrId": "GI39sQo8TF"
    },
    "18mer1416": {
        "label": "Mercoledì 18 Ottobre (ore 14:30 - 16:30)",
        "position": 7,
        "calndrId": "BaNG3i1Sg7"
    }
}

export const Webinar = () => {
    const [watched, setWatched] = useLocalStorage('watched', false)
    const {user} = useUserStore()
    const {test} = useTestStore()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [success, setSuccess] = useState(true)

    const [testOpened, loadingTestOpened, errorTestOpened] =
        useDocument(firestore.doc(`sessions/${CURRENT_SESSION_ID}/webinars/${user?.slotWebinar}`))

    useEffect(() => {
        if (user && test.length > 0) {
            let t = test.filter((t) => t.session_id === '0723').filter(t => t.test_passed === true)
            if (t.length > 0) {
                setSuccess(true)
            }
        }
    }, [test, user])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const theme = useTheme()

    return (
        <Platform>
            {/*<Modal open={!watched} onClose={() => setWatched(true)}>
                <Container maxWidth={'md'} sx={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    outline: 'none'
                }}>
                    <Card sx={{width: '100%', position: 'relative'}}>
                        <IconButton sx={{position: 'absolute', top: '1rem', right: '1rem'}} variant={'contained'}
                                    onClick={() => setWatched(true)}>
                            <Close/>
                        </IconButton>
                        <CardContent>
                            <Typography variant={'h6'} sx={{
                                textAlign: 'center',
                                fontFamily: 'Dacia Block Extended',
                                color: theme.palette.primary.main
                            }}>
                                BENVENUTO
                            </Typography>
                            <Typography textAlign={'center'} sx={{pb: 2}}>
                                Rudy Bandiera introduce il programma Dacia Empowerment
                            </Typography>
                            <Box sx={{width: '100%'}}>
                                <div style={{padding: "56.25% 0 0 0", position: "relative"}}>
                                    <iframe
                                        src="https://player.vimeo.com/video/838295220?h=7a6fd700fe&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                                        frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
                                        style={{
                                            position: "absolute",
                                            top: "0",
                                            left: "0",
                                            width: "100%",
                                            height: "100%"
                                        }}
                                        title="BENVENUTO - Rudy Bandiera introduce il programma Dacia Empowerment"></iframe>
                                </div>
                            </Box>
                        </CardContent>
                    </Card>
                </Container>
            </Modal>*/}
            {
                testOpened?.data()?.isOpen ?
                    <Stack alignItems={'center'}>
                        {user?.slotWebinar ? <Typography sx={{textAlign: 'center', pt: 1, pb: 3}} variant={'h6'}>
                                Hai partecipato al webinar del
                                giorno {user?.slotWebinar ? webinarMap[user.slotWebinar]["label"] : ''}
                            </Typography>
                            : <Typography sx={{textAlign: 'center', pt: 1, pb: 3}} variant={'h6'}>
                                Non ti risulta assegnato nessuno slot webinar
                            </Typography>}
                        <Typography sx={{textAlign: 'center', pt: 1, pb: 3}} variant={'h6'}>
                            Per qualsiasi approfondimento, puoi rivedere le registrazioni dei webinar all’interno della
                            sezione Contenuti.
                        </Typography>
                        <Button component={Link} to={"contenuti"} variant={"contained"}>
                            Vai ai contenuti
                        </Button>
                    </Stack>
                    : <>
                        {user?.slotWebinar ? <Typography sx={{textAlign: 'center', pt: 1, pb: 3}} variant={'h6'}>
                                Il webinar inizierà il giorno {user?.slotWebinar ? webinarMap[user.slotWebinar]["label"] : ''}
                            </Typography>
                            : <Typography sx={{textAlign: 'center', pt: 1, pb: 3}} variant={'h6'}>
                                Non ti risulta assegnato nessuno slot webinar
                            </Typography>}
                        {<Box sx={{textAlign: 'center', pb: 3}}>
                            <Button variant={'outlined'} startIcon={<CalendarToday/>} onClick={handleClick} size={'small'}>
                                SALVA LA DATA IN AGENDA
                            </Button>
                        </Box>}
                        {user?.slotWebinar && <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem component={'a'}
                                      href={`https://calndr.link/e/${webinarMap[user.slotWebinar]["calndrId"]}?s=google`}
                                      target={'_blank'}>
                                <ListItemIcon>
                                    <Google/>
                                </ListItemIcon>
                                <ListItemText>Google</ListItemText>
                            </MenuItem>
                            <MenuItem component={'a'}
                                      href={`https://calndr.link/e/${webinarMap[user.slotWebinar]["calndrId"]}?s=outlook`}
                                      target={'_blank'}>
                                <ListItemIcon>
                                    <Icon>
                                        <img style={{width: '100%', objectFit: 'cover'}}
                                             src={require('../assets/images/outlook.png')}/>
                                    </Icon>
                                </ListItemIcon>
                                <ListItemText>Outlook</ListItemText>
                            </MenuItem>
                            <MenuItem component={'a'}
                                      href={`https://calndr.link/e/${webinarMap[user.slotWebinar]["calndrId"]}?s=apple`}
                                      target={'_blank'}>
                                <ListItemIcon>
                                    <Apple/>
                                </ListItemIcon>
                                <ListItemText>Apple</ListItemText>
                            </MenuItem>
                        </Menu>}
                        <Box sx={{textAlign: 'center'}}>
                            <Button disabled={(!user["zoomUrl"] || user["zoomUrl"] === "") || !success}
                                    variant={'contained'}
                                    href={user["zoomUrl"]} target={'_blank'} size={'large'}>
                                partecipa
                            </Button>
                        </Box>
                    </>
            }

            {/*!success && <Box>
                <Typography textAlign={'center'} sx={{pt: 3}} fontSize={18} fontWeight={'bold'}>
                    Per accedere al webinar è necessario aver superato il test della sessione di Luglio
                </Typography>
                <Typography textAlign={'center'} sx={{py: 1}}>
                    Puoi visionare il webinar della scorsa sessione qui sotto
                </Typography>
                <Box sx={{width: 'min(35rem, 100%)', margin: 'auto'}}>
                    <div style={{padding: "56.25% 0 0 0", position: "relative"}}>
                        <iframe
                            src={'https://player.vimeo.com/video/842458284?h=79423cb817&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'}
                            frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
                            style={{position: "absolute", top: "0", left: "0", width: "100%", height: "100%"}}
                            title="BENVENUTO - Rudy Bandiera introduce il programma Dacia Empowerment"></iframe>
                    </div>
                </Box>
            </Box>*/}
            {/*<Typography textAlign={'center'} fontSize={24} fontFamily={'Dacia Block Extended'}>
                In aggiornamento...
            </Typography>*/}
        </Platform>
    )
}
