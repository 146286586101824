import React, {useEffect} from "react";
import {Platform} from "../layouts/Platform";
import {Box, Button, CircularProgress, Divider, Stack, Typography} from "@mui/material";
import axios from "axios";
import {API_URL, CURRENT_SESSION_ID, sessions} from "../config";
import {getAuthToken} from "../functions/getAuthToken";
import {Domanda} from "../components/Test/Domanda";
import {motion, useMotionValue} from "framer-motion";
import SuccessCheck from '../components/shared/SuccessCheck'
import {useTestStore} from "../store/testStore";
import {green, red} from "@mui/material/colors";
import {useNavigate, useParams} from "react-router-dom";
import {useDocument} from "react-firebase-hooks/firestore";
import {firestore} from "../firebase/clientApp";
import {useUserStore} from "../store/userStore";
import {WrongAnswer} from "../components/Test/WrongAnswer";
import {ArrowBack} from "@mui/icons-material";

export const TestGenerico = () => {
    const {session_id} = useParams()
    const navigate = useNavigate()

    const [tests, setTests] = React.useState([])
    const [started, setStarted] = React.useState(false)
    const [currentQuestion, setCurrentQuestion] = React.useState(0)
    const [loading, setLoading] = React.useState(false)
    const [success, setSuccess] = React.useState(false)
    const [sending, setSending] = React.useState(false)
    const [error, setError] = React.useState(false)
    const [failed, setFailed] = React.useState(false)
    const [answers, setAnswers] = React.useState([])
    const [wrongAnswers, setWrongAnswers] = React.useState([])
    let progress = useMotionValue(90)
    const {test} = useTestStore()
    const {user} = useUserStore()
    const [testOpened, loadingTestOpened, errorTestOpened] =
        useDocument(firestore.doc(`sessions/${session_id}/webinars/${session_id === CURRENT_SESSION_ID 
            ? user?.slotWebinar : sessions.find((o) => (o.id === session_id))?.slotWebinar}`))
    const [oldSuccess, setOldSuccess] = React.useState(true)
    console.log("user:",user)
    console.log("testOpened:",testOpened)

    useEffect(() => {
        if (user && test.length > 0) {
            let t = test.filter((t) => t.session_id === session_id).filter(t => t.test_passed === true)
            if (t.length > 0) {
                setSuccess(true)
            }
            let oldTests = test.filter((t) => t.session_id === '0723').filter(t => t.test_passed === true)
            if (oldTests.length > 0) {
                setOldSuccess(true)
            } else {
                setOldSuccess(false)
            }
        } else if (test.length === 0) {
            setOldSuccess(false)
        }
    }, [test, user])

    const getTestFunc = async (session) => {
        const token = await getAuthToken()
        await axios.get(`${API_URL}/sessions/${session}/questions`, {headers: {Authorization: token}})
            .then((response) => {
                let r = response.data.questions
                setTests(r)
                let a = []
                for (let i = 0; i < r.length; i++) {
                    a.push({
                        questionId: r[i].id,
                        answerId: ""
                    })
                }
                setAnswers(a)
            })
            .catch((error) => {
                setError(true)
            })
    }

    const getTest = async () => {
        setLoading(true)
        await getTestFunc(session_id)
        setLoading(false)
    }

    const startQuiz = async () => {
        await getTest()
            .then(() => {
                setStarted(true)
            })
    }

    const sendTest = async () => {
        setSending(true)
        const token = await getAuthToken()
        await axios.post(`${API_URL}/sessions/${session_id}/check-score`, {answers}, {headers: {Authorization: token}})
            .then((response) => {
                setWrongAnswers(response.data.wrongQuestions)
                let r = response.data
                if (r.score >= 70) {
                    setSuccess(true)
                } else {
                    setFailed(true)
                }
            })
        setSending(false)
    }

    const nextQuestion = async () => {
        if (currentQuestion < tests.length - 1)
            setCurrentQuestion(currentQuestion + 1)
        else {
            await sendTest()
        }
    }

    const prevQuestion = () => {
        if (currentQuestion > 0)
            setCurrentQuestion(currentQuestion - 1)
    }

    const addAnswer = (questionId, answerId) => {
        let a = answers
        a[currentQuestion] = {questionId: questionId, answerId: answerId}
        setAnswers(a)
    }

    const retry = () => {
        setFailed(false)
        setSuccess(false)
        setStarted(false)
        setCurrentQuestion(0)
        setAnswers([])
    }

    return (
        <Platform>
            <Button startIcon={<ArrowBack/>} onClick={() => navigate('/test')}>
                INDIETRO
            </Button>
            {/*
                !oldSuccess && <Box sx={{textAlign: 'center', pt: 1, pb: 3}}>
                    <Typography sx={{textAlign: 'center', pt: 1, pb: 1}} fontSize={17}>
                        Il test qui sotto è relativo al webinar della sessione di <u>Luglio</u>. Trovi i materiali relativi al webinar nella sezione <b>Contenuti</b>
                        <br/>
                        Per accedere al test della sessione di Ottobre è necessario aver superato il test qui sotto
                    </Typography>
                </Box>
            */}
            {!started && <Typography sx={{pt: 1, pb: 3}} textAlign={'center'} variant={'body2'}>
                Completa il test rispondendo, uno per volta, a <b>10 quesiti</b>!
                <br/>
                <br/>
                Raccomandiamo di non interrompere o cambiare pagina durante il test se non vuoi rischiare di perdere il
                lavoro svolto.
                <br/>
                <br/>
                Ogni test verrà ritenuto superato solo se le risposte esatte saranno <b>7 su 10</b> domande poste.
                <br/>
                <br/>
                Nel caso non dovessi superarlo al primo tentativo, potrai riprovarci attraverso il
                pulsante <i>RITENTA</i> che apparirà sulla schermata.
                <br/>
                <br/>
                Ti ricordiamo che nell'<b>AREA PERSONALE</b> potrai visionare in tempo reale il tuo percorso e tenere
                traccia dei risultati ottenuti ad ogni test.
            </Typography>}
            {
                success ?
                    <Box sx={{textAlign: 'center'}}>
                        <Typography sx={{textAlign: 'center', pt: 1, pb: 3}} variant={'h5'} fontWeight={'bold'}
                                    color={green[400]}>
                            TEST SUPERATO
                        </Typography>
                        <Box style={{width: 'max(30%, 10rem)', margin: 'auto'}}>
                            <motion.div
                                initial={{x: 0}}
                                animate={{x: 100}}
                                style={{x: progress}}
                                transition={{duration: 0.8}}
                            />
                            <SuccessCheck progress={progress}/>
                        </Box>
                    </Box>
                    :
                    failed ?
                        <Box sx={{textAlign: 'center'}}>
                            <Typography sx={{textAlign: 'center', pt: 1, pb: 3}} variant={'h5'} fontWeight={'bold'}
                                        color={red[400]}>
                                TEST NON SUPERATO
                            </Typography>
                            <Typography sx={{textAlign: 'center', pt: 1, pb: 3}} variant={'h6'}>
                                Clicca qui sotto per riprovare
                            </Typography>
                            <Box sx={{textAlign: 'center'}}>
                                <Button variant={'contained'} onClick={retry}>
                                    ritenta
                                </Button>
                            </Box>
                            <Box sx={{pt:2}}>
                                <Typography sx={{textAlign: 'center', py: 1}} variant={'h6'}>
                                    Risposte errate:
                                </Typography>
                                <Divider/>
                                <Stack spacing={2} sx={{py:2}}>
                                    {
                                        wrongAnswers.map((w, i) =>
                                            <WrongAnswer question={tests.filter(t => t.id === w)[0]}/>
                                        )
                                    }
                                </Stack>
                            </Box>
                        </Box>
                        :
                        <Box>
                            {
                                loadingTestOpened ?
                                    <Box sx={{textAlign:'center'}}>
                                        <CircularProgress/>
                                    </Box>
                                    :
                                    (testOpened?.data()?.isOpen) ?
                                        <Box>
                                            {!started && <Typography sx={{textAlign: 'center', pt: 1, pb: 3}} variant={'h6'}>
                                                Clicca sul bottone per eseguire il test
                                            </Typography>}
                                            {
                                                !started ?
                                                    <Box sx={{textAlign: 'center'}}>
                                                        {loading ?
                                                            <CircularProgress/> : <Button variant={'contained'} onClick={startQuiz}>
                                                                inizia il test
                                                            </Button>}
                                                    </Box>
                                                    : answers?.length > 0 ?
                                                        <Box>
                                                            <Domanda answer={answers[currentQuestion]} currentQuestion={currentQuestion}
                                                                     first={currentQuestion === 0} last={currentQuestion === 9}
                                                                     test={tests[currentQuestion]} addAnswer={addAnswer}
                                                                     nextQuestion={nextQuestion}
                                                                     prevQuestion={prevQuestion}
                                                                     sending={sending}
                                                            />
                                                        </Box>
                                                    : null
                                            }
                                        </Box>
                                        :
                                        <Box>
                                            <Typography sx={{textAlign: 'center', pt: 1, pb: 3}} variant={'h6'} fontWeight={'bold'}>
                                                Il test sarà disponibile al termine del webinar
                                            </Typography>
                                        </Box>
                            }
                        </Box>

            }
        </Platform>
    )
}
