import React, {useEffect, useState} from "react";
import {Platform} from "../layouts/Platform";
import {Box, Grid, Stack, Typography} from "@mui/material";
import 'react-circular-progressbar/dist/styles.css';
import {useUserStore} from "../store/userStore";
import {useTestStore} from "../store/testStore";
import {TestBox} from "../components/AreaPersonale/TestBox";

export const AreaPersonale = () => {
    const {user} = useUserStore()
    const {test} = useTestStore()
    const [percentage, setPercentage] = useState(0);

    useEffect(() => {
        if(test && test.length>0){
            setPercentage(parseInt(test[0].total_score))
        }
    }, [])

    const SessionTests = ({session, title}) => {
        console.log(test)
        const sessionTests = test?.filter(t => t.session_id === session)
        console.log(sessionTests)

        return (
            <Box sx={{pb:3}}>
                <Typography fontWeight={'bold'} sx={{pb: 2}} color={'#646B52'}>
                    {title}
                </Typography>
                {sessionTests && sessionTests.length>0 ?
                    <Stack spacing={2}>
                        {sessionTests.map((item, index) =>
                            <TestBox test={item} key={index} last={index === sessionTests.length - 1}/>
                        )}
                    </Stack>
                    :
                    <Typography color={'#848484'}>
                        Non hai ancora sostenuto alcun test in questa sessione
                    </Typography>
                }
            </Box>
        )
    }

    return (
        <Platform refresh>
            <Box>
                <Box>
                    <Typography fontFamily={'Dacia Block Extended'} variant={'h6'} sx={{pb: 2}}>
                        Informazioni personali
                    </Typography>
                    <Typography fontFamily={'Dacia Block Extended'} variant={'h5'} color={'#646B52'}>
                        {user.nome} {user.cognome}
                    </Typography>
                    <Typography fontFamily={'Dacia Block Extended'} color={'#646B52'}>
                        {user.email}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{pt:4}}>
                <Typography fontFamily={'Dacia Block Extended'} sx={{pb: 2}} variant={'h6'}>
                    Ultimi test svolti
                </Typography>
                <SessionTests session={'0524'} title={'Test n. 3 - REBRANDING STRATEGY (sessione 3 - Maggio 2024)'}/>
                <SessionTests session={'1023'} title={'Test n. 2 - REBRANDING STRATEGY (sessione 2 - Ottobre 2023)'}/>
                <SessionTests session={'0723'} title={'Test n. 1 - REBRANDING STRATEGY (sessione 1 - Luglio 2023)'}/>
            </Box>
        </Platform>
)
}
