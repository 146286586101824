import React, {useEffect, useState} from "react";
import {Platform} from "../layouts/Platform";
import axios from "axios";
import {API_URL} from "../config";
import {getAuthToken} from "../functions/getAuthToken";
import {Box, Container, Divider, Typography} from "@mui/material";
import {ContenutiBox} from "../components/Contenuti/ContenutiBox";
import {auth} from "../firebase/clientApp";
import {useUserStore} from "../store/userStore";

export const Contenuti = () => {
    const [loading, setLoading] = React.useState(true)
    const [materials, setMaterials] = React.useState([])
    const [link, setLink] = useState("")
    const {user} = useUserStore()

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            getMaterials()
        })
    }, [])

    const getMaterials = async () => {
        setLoading(true)
        let token = await getAuthToken()
        await axios.get(`${API_URL}/materials`,{headers: {Authorization: token}})
            .then(res => {
                setLink(res.data.link)
                let m = res.data.materials
                m.sort((a,b) => b.position - a.position)
                setMaterials(m)
                //setMaterials(materiali)
            })
        setLoading(false)
    }

    return(
        <Platform>
            <Box>
                <Typography variant={'caption'}>
                    *Contenuti, immagini e video hanno scopo formativo e sono ad esclusiva fruizione del personale della concessionaria. È vietato qualunque altro utilizzo che sia promozionale, pubblicitario o di qualunque altra tipologia di comunicazione.
                </Typography>
            </Box>
            {/*(!loading && link!=="") && <Box sx={{py:2}}>
                <Typography sx={{pb:2}} fontWeight={'bold'} variant={'h6'}>
                    REGISTRAZIONE WEBINAR - MAGGIO 2024
                </Typography>
                <div style={{
                    position: 'relative',
                    paddingBottom: '56.25%',
                    height: 0,
                    overflow: 'hidden',
                    width: '100%'
                }}>
                    <iframe
                        src={link}
                        allowFullScreen
                        style={{
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            border: "none"
                        }}></iframe>
                </div>
                <Divider/>
            </Box>*/}
            <Box>
                {(!loading && materials.length>0) &&
                    materials.map((material, index) => (
                        <ContenutiBox material={material} key={index} last={index===materials.length-1}/>
                    ))
                }
            </Box>
        </Platform>
    )
}
