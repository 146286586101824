import React, {useEffect} from "react";
import {useNavigate, useParams, Link} from "react-router-dom";
import {
    Box,
    Button,
    capitalize,
    CircularProgress,
    Container,
    Grid, Snackbar,
    Stack,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import * as Yup from 'yup'
import {useFormik} from 'formik';
import {Footer} from "../components/shared/Footer";
import {WithFooter} from "../layouts/WithFooter";
import axios from "axios";
import {API_URL, CURRENT_SESSION_ID} from "../config";
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Register = () => {
    const {id} = useParams()
    const theme = useTheme()
    const [loading, setLoading] = React.useState(true)
    const navigate = useNavigate()
    const [sending, setSending] = React.useState(false)
    const [success, setSuccess] = React.useState(false)
    const [error, setError] = React.useState(false)
    const [userData, setUserData] = React.useState({
        nome: '',
        cognome: '',
        email: '',
    })

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        if(success === true)
            navigate('/')
    }, [success])

    const getData = async () => {
        setLoading(true)
        await axios.get(`${API_URL}/${CURRENT_SESSION_ID}/verify/${id}`)
            .then(res => {
                let r = res.data.user
                setSuccess(res.data.registered ?? false)
                setUserData({nome: r.nome, cognome: r.cognome, email: r.email})
            })
            .catch(err => {
                navigate('/error')
            })
        setLoading(false)
    }

    const validationSchema = Yup.object({
        password: Yup.string().required('Questo campo è obbligatorio').min(8, 'La password deve essere di almeno 8 caratteri'),
        confirmPassword: Yup.string()
            .required('Questo campo è obbligatorio')
            .oneOf([Yup.ref('password'), null], 'Le due password devono coincidere')
    });

    const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setSending(true)
            await axios.post(`${API_URL}/register`, {...values, id, email:userData.email})
                .then(res => {
                    setSuccess(true)
                })
                .catch(err => {
                    setError(true)
                })
            setSending(false)
        },
    });

    return (
        <WithFooter>
            <Snackbar open={error} autoHideDuration={6000} onClose={() => setError(false)}>
                <Alert  onClose={() => setError(false)} severity="error" sx={{ width: '100%' }}>
                    C'è stato un errore, si prega di riprovare
                </Alert>
            </Snackbar>
            <Box>
                <img style={{width: '100%'}} src={require('../assets/images/header.jpg')}/>
            </Box>
            {
                loading ?
                    <Box sx={{textAlign:'center'}}>
                        <CircularProgress/>
                    </Box>
                    :
                    !success ? <Container maxWidth={'md'} sx={{py: 2}}>
                            <Typography variant={'h5'} textAlign={'center'}
                                        fontFamily={'Dacia Block Extended'}>Registrazione</Typography>
                            <Typography sx={{py: 2}}>
                                Controlla che le tue informazioni siano corrette e imposta la tua password per completare la
                                registrazione
                            </Typography>
                            <Box>
                                <Typography variant={'h5'} fontFamily={'Dacia Block Extended'}>
                                    Informazioni personali
                                </Typography>
                                <Box sx={{width: '6rem', height: '0.5rem', background: theme.palette.primary.main}}/>
                                <Grid container spacing={2} sx={{pt: 1}}>
                                    {Object.keys(userData).map(k =>
                                        <Grid item xs={12} md={6}>
                                            <TextField variant={'standard'} fullWidth value={userData[k]} label={capitalize(k)}
                                                       disabled/>
                                        </Grid>
                                    )}
                                </Grid>
                            </Box>
                            <Box sx={{pt: 3}}>
                                <Typography variant={'h5'} fontFamily={'Dacia Block Extended'}>
                                    Imposta la tua password
                                </Typography>
                                <Box sx={{width: '6rem', height: '0.5rem', background: theme.palette.primary.main}}/>
                                <form onSubmit={formik.handleSubmit}>
                                    <Grid container spacing={2} sx={{pt: 1}}>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                id="password"
                                                name="password"
                                                label="Password"
                                                variant={'standard'}
                                                type={'password'}
                                                value={formik.values.password}
                                                onChange={formik.handleChange}
                                                error={formik.touched.password && Boolean(formik.errors.password)}
                                                helperText={formik.touched.password && formik.errors.password}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                id="confirmPassword"
                                                name="confirmPassword"
                                                label="Conferma password"
                                                variant={'standard'}
                                                type={'password'}
                                                value={formik.values.confirmPassword}
                                                onChange={formik.handleChange}
                                                error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Box sx={{textAlign: 'center', py: 4}}>
                                        {sending ? <CircularProgress/> : <Button variant={'contained'} type={'submit'}>
                                            Registrati
                                        </Button>}
                                    </Box>
                                </form>
                            </Box>
                        </Container>
                        :
                        <Container maxWidth={'md'}>
                            <Typography variant={'h6'} fontWeight={'bold'} textAlign={'center'}>
                                Hai completato correttamente la procedura di registrazione, ora puoi accedere al tuo account
                            </Typography>
                            <Box sx={{textAlign:'center', py:2}}>
                                <Button variant={'contained'} component={Link} to={'/login'}>
                                    Vai alla pagina di login
                                </Button>
                            </Box>
                            <br/>
                            <Typography variant={'body2'} textAlign={'center'}>
                                Se non ti sei registrato in questo momento è perchè ti sei già registrato nelle precedenti sessioni.
                                <br/>Puoi usare le credenziali che hai già creato in precedenza.
                                <br/>Se non te le ricordi utilizza la mail alla quale hai ricevuto la comunicazione come username e reimpostare la password
                        </Typography>
                        </Container>
            }
        </WithFooter>
    )
}
